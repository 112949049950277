import logo from '../assets/img/logo.webp';

const Header = (): JSX.Element => {
  return (
    <header>
      <div className="flex flex-col text-center px-12 mt-5 lg:mt-14 mb-6">
        <p className="upsercase text-4xl lg:text-4xl font-bold font-Midnight text-rojoOscuro">
          Sociedad del Aprendizaje de Extremadura
        </p>
        <p className="uppercase text-3xl font-bold font-Midnight text-rojoOscuro mt-2">
          5º congreso
        </p>
      </div>
      <img src={logo} alt="logo" className="" />
    </header>
  );
};

export default Header;
