import { Field, FormikErrors, FormikTouched } from 'formik';
import { FormEvent } from 'react';

type FormProps = {
  errors: FormikErrors<Inscripcion>;
  formValues: Inscripcion;
  values: Inscripcion;
  touched: FormikTouched<Inscripcion>;
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
  handleChange: (
    event: FormEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
};

type FieldArgs = {
  formProps: FormProps;
};

export const SectorEducacionSelect = ({
  formProps
}: FieldArgs): JSX.Element => {
  const { errors, touched, formValues, values, handleBlur, handleChange } =
    formProps;
  return (
    <div className="mt-2">
      <label className="text-xl md:text-lg" htmlFor="educativo_sector">
        Sector educativo al que pertenece:
      </label>
      <Field
        as="select"
        id="educativo_sector"
        value={formValues.educativo_sector}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleChange(e, values)
        }
        name="educativo_sector"
        placeholder="Sector educativo"
        onBlur={handleBlur}
        className="bg-azulClaro text-black h-12 w-11/12 md:w-11/12 mb-3 px-2 border-gray-400 rounded-md text-md lg:text-lg"
      >
        <option value="UN">Universitario</option>
        <option value="BA">Bachillerato</option>
        <option value="ES">ESO</option>
        <option value="FP">FP</option>
        <option value="CF">Ciclos formativos</option>
        <option value="PI">Primaria/Infantil</option>
      </Field>
      {errors.educativo_sector && touched.educativo_sector && (
        <div className="text-paleta-rojo">{errors.educativo_sector}</div>
      )}
    </div>
  );
};
