import { useField } from 'formik';

type Props = {
  fieldName: string;
  fieldLabel: string;
};

export const CheckBoxTemplate = ({
  fieldName,
  fieldLabel
}: Props): JSX.Element => {
  const [field] = useField({
    name: fieldName,
    type: 'checkbox'
  });
  return (
    <div className="font-bold px-3">
      <input
        {...field}
        className="mr-2 my-3 h-5 w-5  leading-tight"
        type="checkbox"
      />
      <label className="text-sm text-gray-600 uppercase">{fieldLabel}</label>
    </div>
  );
};
