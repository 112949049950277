import batterfly from '../assets/img/mariposa_circle.png';
import { userName } from '../utils';

const RegistroOk = (): JSX.Element => {
  const { nombre } = userName()!;
  return (
    <div className="max-w-md max-h-96 py-4 px-8 bg-white shadow-lg rounded-lg my-20">
      <div className="flex justify-center md:justify-end -mt-16">
        <img
          className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500"
          src={batterfly}
        />
      </div>
      <div>
        <h2 className="text-rojoOscuro text-3xl font-Midnight">Recibida!</h2>
        <p className="mt-2 text-gray-600">
          Estimado/a <span className="text-indigo-800 font-bold">{nombre}</span>
          ,
          <br />
          Desde la organización del V Congreso de la Sociedad del Aprendizaje de
          de Extremadura te comunicamos que tu inscripción se ha realizado
          correctamente. En breve,{' '}
          <span className="font-bold">
            recibirás un correo electrónico en el que te confirmaremos la
            aceptación o no
          </span>{' '}
          de la misma. Para cualquier duda puedes contactar con la organización
          a través del correo{' '}
          <a href="mailto:congresoaprendizaje@juntaex.es">
            congresoaprendizaje@juntaex.es
          </a>
          .
        </p>
        <p className="mt-3 text-gray-600">
          Permanece pendiente del correo así como de la{' '}
          <a
            className="text-rojoOscuro font-bold"
            href="https://comunidadlsa.es/"
          >
            Comunidad LSA
          </a>
          .
        </p>
      </div>
    </div>
  );
};
export default RegistroOk;
