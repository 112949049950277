import { Field, FormikErrors, FormikTouched } from 'formik';
import { FormEvent } from 'react';

type FormProps = {
  errors: FormikErrors<Inscripcion>;
  formValues: Inscripcion;
  values: Inscripcion;
  touched: FormikTouched<Inscripcion>;
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
  handleChange: (
    event: FormEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
};

type FieldArgs = {
  formProps: FormProps;
};

export const SectorEmpresarialSelect = ({
  formProps
}: FieldArgs): JSX.Element => {
  const { errors, touched, formValues, values, handleBlur, handleChange } =
    formProps;
  return (
    <div className="mt-2">
      <label
        className="text-gray-600 text-md font-bold uppercase lg:text-lg"
        htmlFor="sector_laboral"
      >
        Desarrollo profesional o académico:
      </label>
      <Field
        as="select"
        id="sector_laboral"
        value={formValues.sector_laboral}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleChange(e, values)
        }
        name="sector_laboral"
        placeholder="Desarrollo profesional o académico"
        onBlur={handleBlur}
        className="bg-azulClaro text-black h-12 w-11/12 md:w-11/12 mb-3 px-2 border-gray-400 rounded-md text-md lg:text-lg"
      >
        <option value="CA">Trabajador por cuenta ajena</option>
        <option value="EA">Empresario/Autónomo</option>
        <option value="SE">Sector Educativo</option>
        <option value="FP">Funcionario Público</option>
        <option value="E">Emprendedor</option>
        <option value="O">ONG</option>
        <option value="D">Desempleado</option>
        <option value="ES">Estudiante</option>
        <option value="OL">Otra situación laboral</option>
      </Field>
      {errors.sector_laboral && touched.sector_laboral && (
        <div className="text-paleta-rojo">{errors.sector_laboral}</div>
      )}
    </div>
  );
};
