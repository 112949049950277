import get_dsrf_token from './get_csrf_token';

const post_form = async (formData: Inscripcion): Promise<string> => {
  const url = process.env.REACT_APP_FORM || 'not env defined';

  // const csrftoken = getCookie('csrftoken');
  const result = get_dsrf_token().then(async (csrftoken) => {
    document.cookie = `csrftoken=${csrftoken}`;
    const request = new Request(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      }
    });
    const response = await fetch(request, {
      method: 'POST',
      //mode: 'same-origin', // SOLO PARA PRO
      credentials: 'include',
      body: JSON.stringify(formData)
    });
    const data = await response.json();
    return data['status'];
  });

  return result;
};

export default post_form;
