import Footer from './Footer';
import Header from './Header';
import InscripcionForm from './InscripcionForm';

const Home = (): JSX.Element => {
  return (
    <>
      <Header />
      <InscripcionForm />
      <Footer />
    </>
  );
};

export default Home;
