import logo1 from '../assets/img/colaboradores2-congreso-SAEX-1-300x133.png';
import logo2 from '../assets/img/SAEX-cuestion-de-actitud-1.png';

const Footer = (): JSX.Element => {
  return (
    <footer
      id="footer"
      className="flex w-full flex-col lg:flex-row lg:justify-around lg:my-12"
    >
      <img
        src={logo1}
        className="w-6/12 lg:w-64 self-center my-6"
        alt="colaboradors congreso SAEX"
      />

      <img
        src={logo2}
        className="w-6/12 lg:w-44 self-center my-6"
        alt="Logo aprender es una cuestión de actitud"
      />
    </footer>
  );
};

export default Footer;
