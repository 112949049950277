export const check_existing_email = async (email: string): Promise<boolean> => {
  // Check if a email already exists on backend
  let ALREADY_EXISTS = false;

  const url = process.env.REACT_APP_CHECK_EMAIL + email || 'not env defined';
  let data: any;
  try {
    const response = await fetch(url);
    if (!response.ok) throw response.statusText;
    data = await response.json();
  } catch (e) {
    console.error(e);
  }

  if (data['status'] === 'error') {
    ALREADY_EXISTS = true;
  }
  return ALREADY_EXISTS;
};
