import * as Yup from 'yup';
import { check_existing_email } from '../services';

export const validation_schema = Yup.object().shape({
  nombre: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .required('El campo nombre es obligatorio'),
  apellidos: Yup.string()
    .min(6, 'Mínimo 6 caracteres')
    .required('El campo apellidos es obligatorio'),
  email: Yup.string()
    .min(6, 'Mínimo 6 caracteres')
    .required('El campo email es obligatorio')
    .email('No parece una dirección de correo válida')
    .trim(),
  email2: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Los correos deben coincidir')
    .test(
      `test-email-already-exists`,
      'Correo ya existe si necesitas cualquier rectificación puedes ponerte en contacto a través de congresodelaprendizaje@juntaex.es',
      function (value) {
        if (value) {
          return check_existing_email(value).then((res) => !res);
        }
        return true;
      }
    )
    .trim(),
  telefono: Yup.string()
    .length(9, `La longitud del campo teléfono es de 9`)
    .required('El campo teléfono es obligatorio'),
  localidad: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .required('El campo localidad es obligatorio'),
  edad: Yup.number().required('El campo edad es obligatorio'),
  // dni: Yup.string().required('El campo dni es obligatorio'),
  localidad_trabajo: Yup.string().required(
    'El campo localidad de trabajo es obligatorio'
  ),
  sexo: Yup.mixed()
    .oneOf(['V', 'M'])
    .required('El campo género es obligatorio'),
  // detalle_comentario_hilo: Yup.string()
  //   .min(15, 'Mínimo 15 caracteres')
  //   .required('Este campo es obligatorio'),
  fecha_comentario: Yup.string()
    .test(
      'len',
      'Formato en 10 caracteres: ej. 13-09-2022',
      (val) => val?.length === 10
    )
    .required('Este campo es obligatorio'),
  email_comentario: Yup.string()
    .min(6, 'Mínimo 6 caracteres')
    .required('El campo email de la Comunidad LSA es obligatorio')
    .email('No parece una dirección de correo válida')
    .trim(),
  sector_laboral: Yup.mixed().oneOf(
    ['SE', 'FP', 'CA', 'EA', 'E', 'O', 'D', 'ES', 'OL'],
    'Sector laboral no válido'
  ),
  educativo_sector: Yup.mixed().oneOf(
    ['', 'UN', 'BA', 'ES', 'FP', 'CF', 'PI'],
    'Sector educativo no válido'
  ),
  funcionario_sector: Yup.string().when('sector_laboral', {
    is: 'FP',
    then: Yup.string()
      .required('El campo funcionario es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  trabajador_cuenta_ajena_sector: Yup.string().when('sector_laboral', {
    is: 'CA',
    then: Yup.string()
      .required('Sector de trabajado por cuenta ajena es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  empresario_sector: Yup.string().when('sector_laboral', {
    is: 'EA',
    then: Yup.string()
      .required('El campo sector de la empresa es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  emprendedor_sector: Yup.string().when('sector_laboral', {
    is: 'E',
    then: Yup.string()
      .required('El campo sector de emprendimiento es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  ong_sector: Yup.string().when('sector_laboral', {
    is: 'O',
    then: Yup.string()
      .required('El campo sector de la ONG es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  estudiante_especialidad: Yup.string().when('sector_laboral', {
    is: 'ES',
    then: Yup.string()
      .required('Especialidad de estudios es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  otro_area_laboral: Yup.string().when('sector_laboral', {
    is: 'OL',
    then: Yup.string()
      .required('Detalle del sector loboral es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  // conoce_lsa: Yup.boolean(),
  participado_accion_lsa: Yup.boolean(),
  asistido_congreso_uno: Yup.boolean(),
  asistido_congreso_dos: Yup.boolean(),
  asistido_congreso_tres: Yup.boolean(),
  asistido_congreso_cuatro: Yup.boolean(),
  // conoce_eslogan_congreso_tres: Yup.boolean(),
  // conoce_eslogan_congreso_tres_detalle: Yup.string().when(
  //   'conoce_eslogan_congreso_tres',
  //   {
  //     is: true,
  //     then: Yup.string()
  //       .required('Indique el eslogan del 3er Congreso, por favor.')
  //       .max(300, 'Máximo 300 caracteres')
  //   }
  // ),
  // conoce_comunidad_lsa: Yup.boolean(),
  // registrado_comunidad_lsa: Yup.boolean(),
  aplicado_conocimientos: Yup.boolean(),
  aplicado_conocimientos_detalle: Yup.string().when('aplicado_conocimientos', {
    is: true,
    then: Yup.string()
      .required('El campo aplicado conocimientos detalle es obligatorio')
      .max(300, 'Máximo 300 caracteres')
  }),
  // jornada_experienciales: Yup.mixed()
  //   .oneOf(['IN'], 'Campo jornada no válido')
  //   .required('El campo jornada experienciales es obligatorio'),
  primer_taller: Yup.mixed()
    .oneOf(
      ['A', 'B', 'C', 'F', 'J', 'K', 'L'],
      'Campo taller válido, verifica que el aforo de la fecha elegida no esté completo'
    )
    .required('El campo taller en enero es obligatorio'),
  segundo_taller: Yup.mixed()
    .oneOf(
      ['A', 'B', 'C', 'F', 'G', 'J', 'K', 'L'],
      'Campo taller válido, verifica que el aforo de la fecha elegida no esté completo'
    )
    .test('taller_no_repeated', 'No se puede repetir taller', function (value) {
      return value !== this.parent.primer_taller;
    })
    .required('El campo taller en mayo es obligatorio'),
  compromiso: Yup.string()
    .required('El campo compromiso es obligatorio')
    .max(300, 'Máximo 300 caracteres'),
  proximo_anio: Yup.string()
    .required('El campo próximo año es obligatorio')
    .max(300, 'Máximo 300 caracteres'),
  desplegar_aprendido: Yup.string()
    .required('El campo desplegar aprendido es obligatorio')
    .max(300, 'Máximo 300 caracteres'),
  competencias_clave_eleccion: Yup.string()
    .required('El campo competencias clave de elección es obligatorio')
    .max(300, 'Máximo 300 caracteres')
});
