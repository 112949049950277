import { Field, FormikErrors, FormikTouched } from 'formik';
import { FormEvent } from 'react';

type FormProps = {
  errors: FormikErrors<Inscripcion>;
  formValues: Inscripcion;
  values: Inscripcion;
  touched: FormikTouched<Inscripcion>;
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
  handleChange: (
    event: FormEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
};

type FieldArgs = {
  formProps: FormProps;
  fieldName: keyof Inscripcion;
  label: string;
};

export const TemplateField = ({
  formProps,
  fieldName,
  label
}: FieldArgs): JSX.Element => {
  const { errors, touched, formValues, values, handleBlur, handleChange } =
    formProps;
  return (
    <div>
      {errors[fieldName] && touched[fieldName] && (
        <div className="text-red-700 font-Ubuntu">{errors[fieldName]}</div>
      )}
      <Field
        id={fieldName}
        value={formValues[fieldName]}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleChange(e, values)
        }
        name={fieldName}
        placeholder={label}
        onBlur={handleBlur}
        className="font-Ubuntu text-black mb-6 h-12 w-11/12 md:w-11/12 mb-3 px-2 rounded-md text-md lg:text-lg"
      />
    </div>
  );
};
