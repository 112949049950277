import { FormikErrors, FormikTouched } from 'formik';
import { FormEvent } from 'react';
import { SectorEducacionSelect, TemplateField } from '.';

type FormProps = {
  errors: FormikErrors<Inscripcion>;
  formValues: Inscripcion;
  values: Inscripcion;
  touched: FormikTouched<Inscripcion>;
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
  handleChange: (
    event: FormEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
};

type Props = {
  formProps: FormProps;
  sectorLaboral: SECTOR_LABORAL;
};
export const ConditionalField = ({
  formProps,
  sectorLaboral
}: Props): JSX.Element => {
  switch (sectorLaboral) {
    case 'SE':
      return <SectorEducacionSelect formProps={formProps} />;
    case 'FP':
      return (
        <TemplateField
          formProps={formProps}
          fieldName="funcionario_sector"
          label="Sector en el que trabaja de la Administración Pública"
        />
      );
    case 'CA':
      return (
        <TemplateField
          formProps={formProps}
          fieldName="trabajador_cuenta_ajena_sector"
          label="Sector en el que trabaja"
        />
      );
    case 'EA':
      return (
        <TemplateField
          formProps={formProps}
          fieldName="empresario_sector"
          label="Sector loboral de su empresa"
        />
      );
    case 'E':
      return (
        <TemplateField
          formProps={formProps}
          fieldName="emprendedor_sector"
          label="Sector en el que emprende"
        />
      );
    case 'O':
      return (
        <TemplateField
          formProps={formProps}
          fieldName="ong_sector"
          label="Sector de la ONG"
        />
      );
    case 'ES':
      return (
        <TemplateField
          formProps={formProps}
          fieldName="estudiante_especialidad"
          label="Especialidad que estudia"
        />
      );
    case 'OL':
      return (
        <TemplateField
          formProps={formProps}
          fieldName="otro_area_laboral"
          label="Sector laboral en el que ejerce"
        />
      );
    default:
      return <></>;
  }
};
