import { Form, Formik } from 'formik';
import React from 'react';
import post_form from '../services/post_form';
import { validation_schema } from '../utils/';
import { history } from '../utils';
import {
  SectorEmpresarialSelect,
  TemplateField,
  SexoSelect,
  ConditionalField,
  CheckBoxTemplate
  // JornadaExperiencialesSelect
} from './FormFields';
import BeatLoader from 'react-spinners/BeatLoader';
import { userName } from '../utils/userContext';
import { PrimerTallerSelect } from './FormFields/PrimerTallerSelect';
import { SegundoTallerSelect } from './FormFields/SegundoTallerSelect';
// import GuiaPDf from '../assets/doc/PDF_informativo_IV_Congreso_LSA.pdf';

const initialState: Inscripcion = {
  nombre: '',
  apellidos: '',
  email: '',
  email2: '',
  telefono: '',
  localidad: '',
  edad: 41,
  dni: '',
  localidad_trabajo: '',
  // detalle_comentario_hilo: '',
  fecha_comentario: '',
  email_comentario: '',
  sexo: 'M',
  sector_laboral: 'CA', // TRABAJADOR_POR_CUENTA_AJENA
  educativo_sector: '',
  funcionario_sector: '',
  trabajador_cuenta_ajena_sector: '',
  empresario_sector: '',
  emprendedor_sector: '',
  ong_sector: '',
  estudiante_especialidad: '',
  otro_area_laboral: '',
  // conoce_lsa: false,
  participado_accion_lsa: false,
  asistido_congreso_uno: false,
  asistido_congreso_dos: false,
  asistido_congreso_tres: false,
  asistido_congreso_cuatro: false,
  // conoce_eslogan_congreso_tres: false,
  // conoce_eslogan_congreso_tres_detalle: '',
  // conoce_comunidad_lsa: false,
  // registrado_comunidad_lsa: false,
  aplicado_conocimientos: false,
  aplicado_conocimientos_detalle: '',
  // jornada_experienciales: 'IN', // INTERESADO EN MÁS INFO
  primer_taller: 'A',
  segundo_taller: 'B',
  compromiso: '',
  proximo_anio: '',
  proyectos: '',
  desplegar_aprendido: '',
  competencias_clave_eleccion: ''
};

const InscripcionForm = (): JSX.Element => {
  const [formValues, setFormValues] = React.useState(initialState);
  const [buttonColor, setButtonColor] = React.useState('#219EBC');
  const [submitting, setSubmitting] = React.useState(false);
  const { setNombre } = userName()!;

  const handleChange = (
    e: React.FormEvent<HTMLInputElement>,
    fValues: Inscripcion
  ) => {
    const itemKey: keyof Inscripcion = e.currentTarget
      .name as keyof Inscripcion;
    const itemValue: string = e.currentTarget.value;
    setFormValues((prevState) => {
      return {
        ...prevState,
        [itemKey]: itemValue
      };
    });
    fValues[itemKey] = itemValue as never;
    if (itemKey === 'nombre') {
      setNombre(itemValue);
    }
  };
  return (
    <div className="px-6 lg:pl-12">
      <Formik
        initialValues={initialState}
        validationSchema={validation_schema}
        validateOnBlur={true}
        onSubmit={(values, actions) => {
          setButtonColor('#E0E0E0');
          //alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
          setSubmitting(true);
          post_form(values).then((result) => {
            switch (result) {
              case 'recibida': {
                history.push('/recibida');
                break;
              }
              case 'reserva': {
                history.push('/reserva');
                break;
              }
              case 'denegada': {
                history.push('/denegada');
                break;
              }
            }
          });
        }}
      >
        {(props) => {
          const { values, touched, errors, handleBlur } = props;
          const formProps = {
            errors,
            formValues,
            values,
            touched,
            handleBlur,
            handleChange
          };
          //console.log(errors);
          return (
            <div className="mt-12">
              <h1 className="uppercase text-xl lg:text-3xl font-DINCondensed-Bold font-bold my-3 lg:my-2 text-azulOscuro">
                Realiza la Inscripción
              </h1>
              <Form>
                <TemplateField
                  formProps={formProps}
                  fieldName="nombre"
                  label="Nombre"
                />
                <TemplateField
                  formProps={formProps}
                  fieldName="apellidos"
                  label="Apellidos"
                />
                <p>
                  INDICA LA FECHA E EMAIL DE TU MENSAJE QUE HAS GENERADO EN LA
                  COMUNIDAD LSA correspondiente a la preinscripción del V
                  Congreso LSA 2023 donde se te indicaba que pusieras: -
                  Identifica la EMOCIÓN o SENTIMIENTO - Describe un APRENDIZAJE
                  - y Comprométete a una ACCIÓN
                </p>
                {/* <TemplateField
                  formProps={formProps}
                  fieldName="detalle_comentario_hilo"
                  label="Fecha y dirección de email"
                /> */}
                <TemplateField
                  formProps={formProps}
                  fieldName="fecha_comentario"
                  label="Fecha del comentario"
                />
                <TemplateField
                  formProps={formProps}
                  fieldName="email_comentario"
                  label="Dirección de email en la Comunidad LSA"
                />
                <br />
                <span className="">Edad</span>
                <TemplateField
                  formProps={formProps}
                  fieldName="edad"
                  label="Edad"
                />
                <label
                  htmlFor="dni"
                  className="text-sm font-bold text-gray-600"
                >
                  * DNI Requerido si quieres un documento "Hago Constar" al
                  finalizar, y solo tras haber asistido a la jornada y los dos
                  talleres:
                </label>
                <TemplateField
                  formProps={formProps}
                  fieldName="dni"
                  label="DNI"
                />
                <TemplateField
                  formProps={formProps}
                  fieldName="email"
                  label="Email"
                />
                <TemplateField
                  formProps={formProps}
                  fieldName="email2"
                  label="Confirmar Email"
                />
                <TemplateField
                  formProps={formProps}
                  fieldName="telefono"
                  label="Teléfono"
                />
                <TemplateField
                  formProps={formProps}
                  fieldName="localidad"
                  label="Localidad"
                />
                <TemplateField
                  formProps={formProps}
                  fieldName="localidad_trabajo"
                  label="Localidad de trabajo"
                />
                <SexoSelect formProps={formProps} />
                <SectorEmpresarialSelect formProps={formProps} />
                <ConditionalField
                  formProps={formProps}
                  sectorLaboral={formValues.sector_laboral}
                />
                {/* <CheckBoxTemplate
                  fieldName="conoce_lsa"
                  fieldLabel="¿Conoces La Sociedad del Aprendizaje?"
                /> */}
                <br />
                <CheckBoxTemplate
                  fieldName="participado_accion_lsa"
                  fieldLabel="¿Has participado en Acción LSA?"
                />
                <br />
                <CheckBoxTemplate
                  fieldName="asistido_congreso_uno"
                  fieldLabel="¿Has asistido al 1er Congreso LSA?"
                />
                <br />
                <CheckBoxTemplate
                  fieldName="asistido_congreso_dos"
                  fieldLabel="¿Has asistido al 2º Congreso LSA?"
                />
                <br />
                <CheckBoxTemplate
                  fieldName="asistido_congreso_tres"
                  fieldLabel="¿Has asistido al 3er Congreso LSA?"
                />
                <br />
                <CheckBoxTemplate
                  fieldName="asistido_congreso_cuatro"
                  fieldLabel="¿Has asistido al 4º Congreso LSA?"
                />
                <br />
                {/* <CheckBoxTemplate
                  fieldName="conoce_eslogan_congreso_tres"
                  fieldLabel="¿Conoces el eslogan del 3er Congreso LSA?"
                />
                <br /> */}
                {/* {values.conoce_eslogan_congreso_tres && (
                  <TemplateField
                    formProps={formProps}
                    fieldName="conoce_eslogan_congreso_tres_detalle"
                    label="Eslogan del tercer congreso"
                  />
                )} */}
                {/* <CheckBoxTemplate
                  fieldName="conoce_comunidad_lsa"
                  fieldLabel="¿Conoces la web de la Comunidad LSA?"
                /> */}
                {/* <br /> */}
                {/* <CheckBoxTemplate
                  fieldName="registrado_comunidad_lsa"
                  fieldLabel="¿Estás registrado en la Comunidad LSA?"
                /> */}
                <br />
                <CheckBoxTemplate
                  fieldName="aplicado_conocimientos"
                  fieldLabel="¿Has aplicado conocimientos de LSA?"
                />
                <br />
                {values.aplicado_conocimientos && (
                  <TemplateField
                    formProps={formProps}
                    fieldName="aplicado_conocimientos_detalle"
                    label="Explica cómo has aplicado los conocimientos que te aporta LSA"
                  />
                )}
                {/* <JornadaExperiencialesSelect formProps={formProps} /> */}
                <p>
                  Como ya te hemos comentado en el Hilo de La Comunidad LSA,
                  cada tarde de los días 19 y 20 de enero en el contexto del V
                  Congreso podrás seleccionar un Taller de Formación
                  Experiencial relacionadas con las Guías / Manuales de la
                  estrategia de La Sociedad del Aprendizaje en Extremadura.
                </p>
                <PrimerTallerSelect formProps={formProps} />
                <SegundoTallerSelect formProps={formProps} />
                <label
                  className="text-md font-bold text-gray-600 uppercase"
                  htmlFor="compromiso"
                >
                  Compromiso
                </label>
                <TemplateField
                  formProps={formProps}
                  fieldName="compromiso"
                  label="Me comprometo a..."
                />
                <label
                  className="text-md font-bold text-gray-600 uppercase"
                  htmlFor="proximo_anio"
                >
                  ¿Qué estás dispuesto hacer el próximo año con todo lo
                  aprendido en el marco de la Sociedad del Aprendizaje?
                </label>
                <TemplateField
                  formProps={formProps}
                  fieldName="proximo_anio"
                  label="Aplicaré lo aprendido..."
                />
                <label
                  className="text-md font-bold text-gray-600 uppercase"
                  htmlFor="proyectos"
                >
                  ¿Tienes idea o proyectos para desarrollar o implementar en tu
                  vida?
                </label>
                <TemplateField
                  formProps={formProps}
                  fieldName="proyectos"
                  label="Proyectos..."
                />
                <label
                  className="text-md font-bold text-gray-600 uppercase"
                  htmlFor="desplegar_aprendido"
                >
                  ¿Cómo desplegarías lo aprendido?
                </label>
                <TemplateField
                  formProps={formProps}
                  fieldName="desplegar_aprendido"
                  label="¿Cómo desplegarías lo aprendido?"
                />
                <label
                  htmlFor="competencias_clave_eleccion"
                  className="text-md font-bold text-gray-600 uppercase"
                >
                  Puedes decirnos, de las 10 competencias clave mencionadas en
                  este documento, ¿cuáles priorizarías para desarrollar más y
                  aplicarlas en tu vida?
                </label>
                <TemplateField
                  formProps={formProps}
                  fieldName="competencias_clave_eleccion"
                  label=""
                />

                <button
                  id="new-contact-submit"
                  className="bg-azulOscuro my-5 hover:bg-blue-400 text-white font-Ubuntu font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
                  color="primary"
                  type="submit"
                >
                  {submitting ? (
                    <BeatLoader loading={true} size={14} />
                  ) : (
                    'ENVIAR'
                  )}
                </button>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default InscripcionForm;
