import batterfly from '../assets/img/mariposa_circle.png';
import { userName } from '../utils';

const RegistroReserva = () => {
  const { nombre } = userName()!;
  return (
    <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
      <div className="flex justify-center md:justify-end -mt-16">
        <img
          className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500"
          src={batterfly}
        />
      </div>
      <div>
        <h2 className="text-rojoOscuro text-3xl font-Midnight mb-4">
          En reserva
        </h2>
        <p className="mt-2 text-gray-600 mb-3">
          Estimado/a <span className="text-indigo-800 font-bold">{nombre}</span>
        </p>
        <p>
          Desde la organización del V Congreso de la Sociedad del Aprendizaje de
          Extremadura te comunicamos que tu solicitud de participación ha sido
          registrada en el sistema en estado de RESERVA/EN LISTA DE ESPERA. La
          razón para encontrarte en esta situación es que:
        </p>
        <p className="my-3 pl-3">
          a) Te has inscrito en La Comunidad con fecha posterior al 8 de Enero.
          Al disponer de plazas limitadas, tienen preferencia aquellas personas
          registradas con anterioridad al 8 de Enero de 2023.
        </p>
        Para cualquier duda puedes contactar con la organización a través del
        correo{' '}
        <a href="mailto:congresoaprendizaje@juntaex.es">
          congresoaprendizaje@juntaex.es
        </a>
        .
        <p className="mt-3 text-gray-600">
          Permanece pendiente del correo así como de la{' '}
          <a
            className="text-rojoOscuro font-bold"
            href="https://comunidadlsa.es/"
          >
            Comunidad LSA
          </a>
          .
        </p>
      </div>
    </div>
  );
};
export default RegistroReserva;
