import { Router, Switch, Redirect, Route } from 'react-router-dom';
import Home from './Home';
import { history } from '../utils';
import RegistroOk from './RegistroOk';
import RegistroReserva from './RegistroReserva';
import RegistroDenegada from './RegistroDenegada';

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/recibida">
          <RegistroOk />
        </Route>
        <Route path="/reserva">
          <RegistroReserva />
        </Route>
        <Route path="/denegada">
          <RegistroDenegada />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
