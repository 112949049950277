import './App.css';
import React from 'react';
import Routes from './components/Routes';

export const UserContext = React.createContext<UserContextType | undefined>(
  undefined
);

function App(): JSX.Element {
  const [nombre, setNombre] = React.useState<string>('');
  return (
    <UserContext.Provider value={{ nombre, setNombre }}>
      <div className="App min-h-screen flex justify-center bg-amarilloClaro">
        <header className="App-header bg-amarilloClaro flex flex-wrap w:full lg:w-8/12">
          <Routes />
        </header>
      </div>
    </UserContext.Provider>
  );
}

export default App;
