import { Field, FormikErrors, FormikTouched } from 'formik';
import { FormEvent } from 'react';

type FormProps = {
  errors: FormikErrors<Inscripcion>;
  formValues: Inscripcion;
  values: Inscripcion;
  touched: FormikTouched<Inscripcion>;
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
  handleChange: (
    event: FormEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
};

type FieldArgs = {
  formProps: FormProps;
};

export const SegundoTallerSelect = ({ formProps }: FieldArgs): JSX.Element => {
  const { errors, touched, formValues, values, handleBlur, handleChange } =
    formProps;
  return (
    <div className="mt-2">
      <label
        className="text-md font-bold text-gray-600 uppercase"
        htmlFor="segundo_taller"
      >
        TALLERES de la tarde del 20 de enero:
      </label>
      <Field
        as="select"
        id="segundo_taller"
        value={formValues.segundo_taller}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleChange(e, values)
        }
        name="segundo_taller"
        placeholder="Sector educativo"
        onBlur={handleBlur}
        className="bg-azulClaro text-black h-12 mt-2 w-11/12 md:w-11/12 mb-5 px-2 border-gray-400 rounded-md text-md lg:text-lg"
      >
        <option value="A">1.- Música y estudio de competencias LSA</option>
        <option value="B">2.- Neuroeducación. Como aprende el cerebro.</option>
        <option value="C">3.- Aprendiendo a ser madres y padres</option>
        <option value="D" disabled>
          4.- AFORO COMPLETO - La Oratoria del Siglo XXI. Habla bien para vivir
          mejor
        </option>
        <option value="E" disabled>
          5.- AFORO COMPLETO - Escape room: diversión, reto y gestión de
          habilidades cooperativas.
        </option>
        <option value="F">
          6.- El juego de mesa, vínculo entre la neurociencia y la competencia
          emprendedora
        </option>
        <option value="G">
          7.- Retos&Desafíos del siglo XXI. Roles de Equipo Belbin
        </option>
        <option value="H" disabled>
          8.- AFORO COMPLETO - Gestión de las emociones
        </option>
        <option value="I" disabled>
          9.- AFORO COMPLETO - Líder Coach. Impulsa tu liderazgo interior
        </option>
        <option value="J">10.- Ciencia Divertida en múltiples contextos</option>
        <option value="K">11.- Dialecta</option>
        <option value="L">
          12.- Los valores de la Radio como herramienta de aprendizaje y
          comunicación
        </option>
        <option value="M" disabled>
          13.- AFORO COMPLETO - La Creatividad a través de una experiencia
          inmersiva
        </option>
      </Field>
      {errors.segundo_taller && touched.segundo_taller && (
        <div className="text-red-700 font-Ubuntu mb-12">
          {errors.segundo_taller}
        </div>
      )}
    </div>
  );
};
