import batterfly from '../assets/img/mariposa_circle.png';
import { userName } from '../utils';

const RegistroDenegada = () => {
  const { nombre } = userName()!;
  return (
    <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg my-20">
      <div className="flex justify-center md:justify-end -mt-16">
        <img
          className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500"
          src={batterfly}
        />
      </div>
      <div>
        <h2 className="text-rojoOscuro text-3xl font-Midnight mb-5">
          No nos consta tu correo
        </h2>
        <p className="mt-2 text-gray-600 mb-2">
          Estimado/a <span className="text-indigo-800 font-bold">{nombre}</span>
          ,
        </p>
        <p>
          Desde la organización de la Sociedad del Aprendizaje de Extremadura
          lamentamos comunicarte que tu solicitud de participación en las
          acciones del IV Congreso LSA ha sido DENEGADA. Las razones para su
          denegación son:
          <br />
          <ul className="pl-3 pb-4">
            <li className="py-2">
              <span className="font-bold">{'a) '}</span>No estar registrado en
              <a
                className="text-indigo-800 font-bold"
                href="https://comunidadlsa.es"
              >
                {' '}
                La Comunidad
              </a>
            </li>
            <li className="py-2">
              <span className="font-bold">{'b) '}</span>Se ha registrado en La
              Comunidad con un correo electrónico distinto. Si es así debería
              hacerlo con el mismo.
            </li>
          </ul>
          Para cualquier duda puedes contactar con la organización a través del
          correo{' '}
          <a href="mailto:congresoaprendizaje@juntaex.es">
            congresoaprendizaje@juntaex.es
          </a>
          .
        </p>
      </div>
    </div>
  );
};
export default RegistroDenegada;
