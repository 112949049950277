import { Field, FormikErrors, FormikTouched } from 'formik';
import { FormEvent } from 'react';

type FormProps = {
  errors: FormikErrors<Inscripcion>;
  formValues: Inscripcion;
  values: Inscripcion;
  touched: FormikTouched<Inscripcion>;
  handleBlur: (
    event: React.FocusEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
  handleChange: (
    event: FormEvent<HTMLInputElement>,
    values: Inscripcion
  ) => void;
};

type FieldArgs = {
  formProps: FormProps;
};
export const SexoSelect = ({ formProps }: FieldArgs): JSX.Element => {
  const { errors, touched, formValues, values, handleBlur, handleChange } =
    formProps;
  return (
    <div>
      {errors.sexo && touched.sexo && (
        <div className="text-paleta-rojo">{errors.sexo}</div>
      )}
      <Field
        as="select"
        id="sexo"
        value={formValues.sexo}
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleChange(e, values)
        }
        name="sexo"
        placeholder="Sexo"
        onBlur={handleBlur}
        className="bg-azulClaro text-black h-12 w-11/12 md:w-11/12 mb-3 px-2 border-gray-400 rounded-md text-md lg:text-lg"
      >
        <option value="M">Mujer</option>
        <option value="V">Varón</option>
      </Field>
    </div>
  );
};
